<template>
    <v-dialog v-model="dialog" width="600">
      <alert ref="alert"/>
      <v-card elevation="6">
        <v-card-title>
          {{ $t('dashboard.writeNote') }}
        </v-card-title>
        <div class="d-flex ml-4">
          
          <v-chip-group show-arrows mandatory active-class="primary--text">
            <v-chip 
              class="mr-2"
              v-for="(item, index) in noteTemplates"
              :key="index"
              @click="selectedNoteTemplate(item.message)"
              v-text="item.message"
              color="dark"
              outlined
              pill
            />
          </v-chip-group>
        </div>
        <v-textarea 
          class="ma-6" 
          :label="label"
          v-model="note"
          :rules="rules"
        >
        </v-textarea>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            {{ $t('dashboard.cancel') }}
          </v-btn>
          <v-btn color="success" text @click="saveNoteToServer()">
            {{ $t('dashboard.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
const Alert = () => import("./Alert");

export default {
    components: { Alert },
    data: () => ({
      studentId: '',
      dialog: false,
      note: '',
      rules: [v => v.length <= 50 || 'Max 50 characters are allowed'],
      noteTemplates: [
        { message: 'Call Parents' },
        { message: 'Was Making Noise' },
        { message: 'Had A Fight' },
        { message: 'Was Looking Weak' },
        { message: 'Call Parents' },
        { message: 'Was Making Noise' },
        { message: 'Had A Fight' },
        { message: 'Was Looking Weak' },
      ]
    }),
    computed: {
        label() {
          return this.$t('dashboard.writeNote') + '...';
        }
    },
    methods: {
        toggleDialog(id) {
          this.studentId = id;
          this.dialog = !this.dialog;
        },
        saveNoteToServer() {
          if (this.note.length > 50) {
            return this.$refs.alert.showAlert({ message: 'Character Limit For Note is 50 characters', color: 'red' });
          } 
          if (this.note.length === 0) {
            return this.$refs.alert.showAlert({ message: 'You cannot save an empty note', color: 'warning' });
          }
          this.$store.commit('dashboardOnline/startLoading');
          this.$store.dispatch('dashboardOnline/saveNoteToServer', { id: this.studentId, noteBody: this.note.trim() })
          .then((res) => {
            this.$store.dispatch('dashboardOnline/updateDataForStudent', { id: [ this.studentId ] });
            this.$store.commit('dashboardOnline/stopLoading');
          });
          this.note = '';
          this.dialog = false;
          this.$emit('close-fs-modal');       
        },
        selectedNoteTemplate(noteText) {
          this.note = noteText;
        }
    }
}
</script>
